<template>
    <div class="basic-information">
        <h3 class="basic-information__title">Основная информация</h3>

        <div class="basic-information__section">
            <div class="basic-information__section-title" style="margin-bottom: 16px;">Описание вакансии</div>
            <div class="first-info">
                <div>
                    <VCustomInput placeholder="Название вакансии*" :error="v$.vacancyName.$error"
                        v-model="form.vacancyName" class="basic-information__inp" />
                    <p class="basic-information__inp-error" v-if="v$.vacancyName.$error">Заполните обязательное поле</p>
                </div>

                <div style="position:relative;">
                    <VCustomInput placeholder="Профессия*" :error="v$.speciality.$error || searchNoResul"
                        v-model="form.specialityName" @input="debouncedSearchSpecialties" @focus="searchSpecialties"
                        class="basic-information__inp" />
                    <p class="basic-information__inp-error" v-if="form.specialityName && searchNoResul">Такой
                        специальности нет
                        в базе</p>
                    <p class="basic-information__inp-error"
                        v-if="v$.speciality.$error && (!form.specialityName && !searchNoResul)">Заполните
                        обязательное поле</p>

                    <div class="basic-information__section-search-results"
                        v-if="form.specialityName && showSelectSpeciality && specialtiesList.length">
                        <div class="basic-information__section-search-result" v-for="item in specialtiesList"
                            :key="item.id" :class="{
                        'basic-information__section-search-result_active':
                            form.speciality === item.id,
                    }" @click="selectSpeciality(item)">
                            {{ item.name }}
                        </div>
                    </div>
                </div>

                <div style="position:relative;">
                    <VCustomInput placeholder="Регион*" :error="v$.region.$error || searchRegionsNoResult"
                        v-model="form.regionName" @input="debouncedgetSearchRegions" @focus="getSearchRegions"
                        class="basic-information__inp" />

                    <p class="basic-information__inp-error" v-if="form.regionName && searchRegionsNoResult">Такого
                        региона нет в
                        базе</p>
                    <p class="basic-information__inp-error"
                        v-if="v$.region.$error && (!form.regionName && !searchRegionsNoResult)">Заполните
                        обязательное поле</p>

                    <div class="basic-information__section-search-results"
                        v-if="form.regionName && showSelectRegion && regionsList.length">
                        <div class="basic-information__section-search-result" v-for="item in regionsList" :key="item.id"
                            :class="{
                        'basic-information__section-search-result_active':
                            form.region === item.id,
                    }" @click="selectRegion(item)">
                            {{ item.name }}
                        </div>
                    </div>
                </div>

                <div>
                    <VCustomInput placeholder="Место работы*" :error="v$.placeWork.$error" v-model="form.placeWork"
                        class="basic-information__inp" />
                    <p class="basic-information__inp-error" v-if="v$.placeWork.$error">Заполните обязательное
                        поле</p>
                </div>
            </div>
        </div>
        <div class="basic-information__section">
            <div class="basic-information__section-title">Расскажите о вакансии</div>
            <p class="basic-information__section-subtitle">Не более 2000 символов</p>

            <div class="basic-information__section-form">
                <VEditor placeholder="Расскажите о вакансии*" :error="v$.description.$error" v-model="form.description"
                    class="basic-information__editor" />
                <p class="basic-information__inp-error" v-if="v$.description.$error">Заполните обязательное поле</p>
            </div>
        </div>

        <div class="basic-information__section">
            <div class="basic-information__section-title">Требования</div>

            <div class="basic-information__section-form">
                <div class="basic-information__section-group">
                    <div class="basic-information__small-select-wrapper">
                        <VSelect placeholder="Образование*" :options="workingConditionsList.education_level"
                            :error="v$.educationLevel.$error" :id="1" v-model="form.educationLevel"
                            class="basic-information__small-select" />
                        <p class="basic-information__inp-error" v-if="v$.educationLevel.$error">Заполните обязательное
                            поле</p>
                    </div>

                    <div class="basic-information__small-select-wrapper">
                        <VSelect placeholder="Опыт работы*" :options="workingConditionsList.work_experience"
                            :error="v$.workExperience.$error" :id="2" v-model="form.workExperience"
                            class="basic-information__small-select" />
                        <p class="basic-information__inp-error" v-if="v$.workExperience.$error">Заполните обязательное
                            поле</p>
                    </div>
                </div>
                <div class="basic-information__select-wrapper half-width">
                    <VSelect placeholder="Обязательность указания трудовых функций*" :options="workerFunctionsOptions"
                        :error="v$.workerFunctionsRequired.$error" v-model="form.workerFunctionsRequired"
                        class="basic-information__small-select" />

                    <p class="basic-information__inp-error" v-if="v$.workerFunctionsRequired.$error">Заполните
                        обязательное
                        поле</p>
                </div>
            </div>
        </div>
        <div class="basic-information__section">
            <div class="basic-information__section-title">Условия</div>

            <div class="basic-information__section-form">
                <div class="basic-information__section-group">
                    <div class="basic-information__small-select-wrapper">
                        <VSelect placeholder="График вахты*" :error="v$.schedule.$error"
                            :options="workingConditionsList.schedule" :id="3" v-model="form.schedule"
                            class="basic-information__small-select" />
                        <p class="basic-information__inp-error" v-if="v$.schedule.$error">Заполните обязательное
                            поле</p>
                    </div>

                    <div class="basic-information__small-select-wrapper">
                        <VSelect placeholder="Дорожные расходы*" :error="v$.travelingExpenses.$error"
                            :options="workingConditionsList.traveling_expense" :id="4" v-model="form.travelingExpenses"
                            class="basic-information__small-select" />
                        <p class="basic-information__inp-error" v-if="v$.travelingExpenses.$error">Заполните
                            обязательное поле</p>
                    </div>
                </div>
                <div class="basic-information__section-group">
                    <div class="basic-information__small-select-wrapper">
                        <VSelect placeholder="Условия договора*" :error="v$.agreementConditions.$error"
                            :options="workingConditionsList.terms_of_contract_list" v-model="form.agreementConditions"
                            class="basic-information__small-select" />
                        <p class="basic-information__inp-error" v-if="v$.agreementConditions.$error">Заполните
                            обязательное
                            поле</p>
                    </div>

                    <div class="basic-information__small-select-wrapper">
                        <DatePicker v-model="form.contractTerm" mode="date" :masks="masks" :popover="popover"
                            class="basic-information__date-picker">
                            <template v-slot="{ togglePopover, inputValue, inputEvents }">
                                <div>
                                    <div class="basic-information__date-inp-wrapper"
                                        :class="{ 'basic-information__date-inp-wrapper_disabled': form.agreementConditions !== 'FIXED_TERM', 'basic-information__date-inp-wrapper_error': v$.contractTerm.$error }">
                                        <input class="basic-information__date-inp" placeholder="Срок договора*"
                                            v-mask="'00.00.0000'" :value="inputValue" v-on="inputEvents" />

                                        <div @click="() => togglePopover()"
                                            class="basic-information__date-inp-icon basic-information__date-inp-icon_active">
                                            <img src="@/assets/images/icons/calendar.svg" alt="calendar">
                                        </div>
                                    </div>

                                    <p class="basic-information__date-inp-error" v-if="v$.contractTerm.$error">Заполните
                                        обязательное поле</p>
                                </div>
                            </template>
                        </DatePicker>
                    </div>
                </div>
            </div>

            <div class="basic-information__section-form">
                <div class="basic-information__section-form-header">
                    <div class="basic-information__section-form-title">Зарплата</div>
                    <VSegmentedControl :items="salaryOptions" :modelValue="salarySelected"
                        @update:modelValue="salarySelecte" class="basic-information__section-form-segment" />
                </div>

                <div class="basic-information__section-row basic-information__section-row_salary">
                    <div class="basic-information__small-inp-wrapper salary-input" v-if="!form.isFixSalary">
                        <VCustomInput placeholder="От" type="number" v-model="form.salaryFrom"
                            class="basic-information__small-inp-salary salary-input__input"
                            :data-value-length="salaryCarretStyle(form.salaryFrom)" />
                        <span class="basic-information__small-inp-wrapper salary-input__span">{{
                        formatNumber(form.salaryFrom)
                    }}</span>

                    </div>

                    <div class="basic-information__small-inp-wrapper salary-input" v-if="!form.isFixSalary">
                        <VCustomInput placeholder="До" type="number" v-model="form.salaryTo"
                            class="basic-information__small-inp-salary salary-input__input"
                            :data-value-length="salaryCarretStyle(form.salaryTo)" />
                        <span class="basic-information__small-inp-wrapper salary-input__span">{{
                        formatNumber(form.salaryTo)
                    }}</span>
                        <p class="basic-information__inp-error"
                            v-if="(v$.salaryTo.$error && form.salaryFrom && v$.salaryTo.$errors[0]) || (form.salaryFrom && salaryFromError)">
                            Укажите корректный диапазон зарплаты</p>


                    </div>

                    <div class="basic-information__small-inp-wrapper" v-if="form.isFixSalary">
                        <VCustomInput placeholder="Оклад*" :error="v$.fixSalary.$error" type="number"
                            v-model="form.fixSalary" class="basic-information__small-inp-salary salary-input__input"
                            :data-value-length="salaryCarretStyle(form.fixSalary)" />
                        <span class="basic-information__small-inp-wrapper salary-input__span">{{
                            formatNumber(form.fixSalary)
                            }}</span>
                        <p class="basic-information__inp-error" v-if="v$.fixSalary.$error">Заполните обязательное
                            поле</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import specialties from "@/api/specialties"
import regions from "@/api/regions"
import { useRoute } from "vue-router";
import VSegmentedControl from "../../UI/VSegmentedControl/index.vue";
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';
import { debounce } from "@/services/helpers"

const props = defineProps({
    modelValue: Object,
    workingConditions: Object,
    salaryFromError: Boolean
});

const route = useRoute();

const emit = defineEmits(["update:modelValue"]);

const masks = {
    input: 'DD.MM.YYYY',
}

const popover = ref({
    visibility: '',
    placement: 'bottom',
});
const formatToLocaleString = computed(() => (number) => number.toLocaleString("ru-RU"))

const formatNumber = (number) => {
    if (!number) return;
    return typeof number === 'number' ? number.toLocaleString("ru-RU") : Number(number.trim()).toLocaleString("ru-RU")
}

const form = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});

const salaryOptions = [
    {
        id: "range",
        label: "Диапазон",
    },
    {
        id: "fixed",
        label: "Фиксированная",
    }
]

const salarySelected = ref('range')
const salarySelecte = (val) => {
    salarySelected.value = val
    form.value.isFixSalary = salarySelected.value === 'fixed'
}
const salaryFromRequired = (val) => {
    if (form.value.isFixSalary || (route.meta.type !== 'vacancy' && route.meta.type !== 'template')) {
        return true
    } else {
        return !!val
    }
}

const salaryToRequired = (val) => {
    if (form.value.isFixSalary || (route.meta.type !== 'vacancy' && route.meta.type !== 'template')) {
        return true
    } else {
        return !!val
    }
}

const fixSalaryRequired = (val) => {
    if (!form.value.isFixSalary || (route.meta.type !== 'vacancy' && route.meta.type !== 'template')) {
        return true
    } else {
        return !!val
    }
}

const requiredField = (val) => {
    if (route.meta.type === 'vacancy' || route.params.templateId) {
        return !!val
    }
    if (route.meta.type === 'template') {
        return true
    }
}

const requiredFieldContractTerm = (val) => {
    if (route.meta.type === 'vacancy' || route.params.templateId) {
        if (form.value.agreementConditions !== 'FIXED_TERM') {
            return true
        } else {
            return !!val
        }
    }
    if (route.meta.type === 'template') {
        return true
    }
}

const salaryMin = (val) => {
    if (!val || form.value.isFixSalary) return true
    if (Number(form.value.salaryFrom) > Number(form.value.salaryTo)) {
        return false
    } else {
        return true
    }
}

const rules = computed(() => ({
    vacancyName: { requiredField },
    speciality: { requiredField },
    region: { requiredField },
    placeWork: { requiredField },
    description: { requiredField },
    educationLevel: { requiredField },
    workExperience: { requiredField },
    schedule: { requiredField },
    travelingExpenses: { requiredField },
    salaryTo: { salaryMin },
    fixSalary: { fixSalaryRequired },
    workerFunctionsRequired: { requiredField },
    agreementConditions: { requiredField },
    contractTerm: { requiredFieldContractTerm },
}));
const v$ = useVuelidate(rules, form);

const showSelectSpeciality = ref(false)
const showSelectRegion = ref(false)

const workingConditionsList = computed(() => {
    let list = {};

    for (let key in props.workingConditions) {
        list[key] = [];
        for (let keySelect in props.workingConditions[key]) {
            list[key].push({
                id: keySelect,
                label: props.workingConditions[key][keySelect],
            });
        }
    }

    return list;
});

const search = ref("");
const searchNoResul = ref(false);

const searchRegions = ref("");
const searchRegionsNoResult = ref(false);

const specialtiesList = ref([]);
const regionsList = ref([]);

const searchSpecialties = async () => {
    setTimeout(() => {
        showSelectSpeciality.value = true
    }, 150)

    if (!form.value.specialityName?.length) {
        form.value.speciality = ''
    }

    try {
        specialtiesList.value = await specialties.getSearchSpecialties({
            search: form.value.specialityName,
        });

        searchNoResul.value = !specialtiesList.value.length ? true : false

    } catch (error) {
    }
};

const debouncedSearchSpecialties = debounce(() => searchSpecialties(), 400)
const getSearchRegions = async () => {
    setTimeout(() => {
        showSelectRegion.value = true
    }, 150)


    if (!form.value?.regionName?.length) {
        form.value.region = ''
    }

    try {
        regionsList.value = await regions.getSearchRegions({
            search: form.value.regionName,
        });

        searchRegionsNoResult.value = !regionsList.value.length ? true : false

    } catch (error) {
        console.log(error);
    }
};

const salaryCarretStyle = computed(() => (salary) => {
    if (salary >= 1000 && salary < 1000000) return 1;
    if (salary >= 1000000 && salary < 1000000000) return 2;
    if (salary >= 1000000000 && salary < 1000000000000) return 3;
    if (salary >= 1000000000000) return 4;
    return 0;
})

const addMarginToNumbers = (num) => {
    const divider = 1000
    const marginOffset = 4
    if (num >= 1) {
        return Math.floor(num / divider) * marginOffset
    }
    return 0
}
const debouncedgetSearchRegions = debounce(() => getSearchRegions(), 400)
const selectSpeciality = (option) => {
    form.value.speciality = option.id
    form.value.specialityName = option.name
    showSelectSpeciality.value = false
};

const selectRegion = (option) => {
    form.value.region = option.id
    form.value.regionName = option.name
    showSelectRegion.value = false
};

const workerFunctionsOptions = [
    {
        id: '1',
        label: "Обязательно"
    },
    {
        id: '0',
        label: "Необязательно"
    },
]

onMounted(() => {
    document.addEventListener('click', (e) => {
        const className = '.basic-information__section-search-results'
        if (!e.target.closest(className) || !e.target.className === className) {
            showSelectSpeciality.value = false
            showSelectRegion.value = false
        }
    })
});
</script>

<style scoped lang="scss">
.basic-information {
    width: 100%;
    padding: 24px 24px 29px 24px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0px #0000000A;

    .first-info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
    }

    &__date-inp-wrapper {
        width: 100%;
        height: 48px;
        padding: 0 16px;
        border-radius: 8px;
        background-color: $light;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_disabled {
            position: relative;
            opacity: 0.3;
            user-select: none;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 4;
            }
        }

        &_error {
            border: 1px solid #DD4646;
        }
    }

    &__date-inp {
        width: 100%;
        height: 100%;
        font-size: 14px;
        background-color: transparent;

        &::placeholder {
            color: $dark-blue;
        }

        &_disabled {
            &::placeholder {
                opacity: .3;
            }
        }
    }

    &__date-inp-wrapper_disabled &__date-inp {
        opacity: 0.3;
        cursor: no-drop;
        user-select: none;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 4;
        }
    }

    &__date-inp-error {
        margin-top: 4px;
        font-size: 14px;
        color: #DD4646;
    }

    &__title {
        width: 100%;
        padding-bottom: 20px;
        font-size: 20px;
        font-weight: 500;
        border-bottom: 1px solid $light-gray-1;
    }

    &__section {
        margin-top: 20px;
    }

    &__section-title {
        font-size: 16px;
        font-weight: 500;
    }

    &__section-subtitle {
        margin-top: 4px;
        color: $gray;
        font-size: 14px;
    }

    &__section-form {
        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    &__section-form-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__section-form-title {
        font-size: 16px;
        font-weight: 500;
    }

    &__section-form-segment {
        height: 40px;
        border-radius: 8px;

        &::v-deep(.v-filtration__filter) {
            width: 158px;
            font-weight: 400;
            border-radius: 4px;
        }
    }

    &__section-group {
        margin-top: -16px;
        margin-left: -16px;
        display: flex;
        flex-wrap: wrap;

        &:not(:first-child) {
            margin-top: 0;
        }
    }

    &__select-wrapper {
        margin-top: 16px;
    }

    &__small-select-wrapper {
        width: calc(50% - 16px);
        margin-top: 16px;
        margin-left: 16px;
    }

    &__small-select {
        width: 100%;

        &::v-deep(.v-select__header-arrow) {
            margin-right: 8px;
        }
    }

    &__inp-date {
        position: relative;

        &_disabled {
            opacity: 0.3;
            cursor: no-drop;
            user-select: none;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 4;
            }
        }
    }

    &__big-inp {
        width: 100%;
        margin-top: 16px;
    }

    &__section-row {
        width: 100%;
        margin-top: 16px;
        display: flex;
        justify-content: space-between;

        &_salary {
            margin-top: 12px;
        }
    }

    &__inp-wrapper {
        width: calc(50% - 8px);
    }

    &__small-inp-wrapper {
        position: relative;
        width: calc(50% - 8px);
        font-size: 14px;

        &>.salary-input__input {
            font-size: 14px;
        }
    }

    &__small-inp {
        width: calc(50% - 8px);
    }

    &__small-inp-salary {
        width: 100%;
    }

    &__section-search {
        position: relative;
        width: calc(50% - 8px);
    }

    &__section-search-results {
        position: absolute;
        top: 56px;
        left: 0;
        width: 100%;
        max-height: 256px;
        padding: 8px;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid $light-gray-1;
        box-shadow: 2px 4px 24px 0 #0000001F;
        z-index: 9;
        overflow: auto;
    }

    &__section-search-result {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        overflow: hidden;

        &:hover {
            background-color: $light-gray-1;
            color: $dark;
        }

    }

    &__inp {
        width: 100%;
    }

    &__inp-error {
        margin-top: 4px;
        font-size: 14px;
        color: $red;
    }

    &__editor {

        margin-top: 16px;

        &::v-deep(.v-editor__editor) {
            height: 240px;
        }
    }

    &__checkbox {
        margin-top: 13px;
        display: flex;
        align-items: center;
    }

    &__checkbox-label {
        margin-right: 17px;
        padding-bottom: 4px;
        font-size: 14px;
    }
}

.half-width {
    max-width: calc(50% - 8px);
}

.salary-input {
    position: relative;
    font-size: 14px;

    &__input {

        &[data-value-length='1'] {
            :deep(.v-input__input_focus) {
                margin-left: 4px;
            }
        }

        &[data-value-length='2'] {
            :deep(.v-input__input_focus) {
                margin-left: 8px;
            }
        }

        &[data-value-length='3'] {
            :deep(.v-input__input_focus) {
                margin-left: 12px;
            }
        }

        &[data-value-length='4'] {
            :deep(.v-input__input_focus) {
                margin-left: 16px;
            }
        }

        :deep(.v-input__input_focus) {
            color: transparent;
            background-color: transparent;
            position: relative;
            z-index: +1;
            caret-color: #000;

            &::placeholder {
                opacity: 0;
            }
        }

    }

    &__span {
        position: absolute;
        left: 17px;
        top: 24px;
        font-size: inherit;
        color: inherit;
    }
}
</style>
